import React from "react";
import { Carousel } from "@material-tailwind/react";
import Navbar from "../../components/navbar/navbar";
import Footer from "../../components/footer/footer";
import { Link } from "react-router-dom";

function Home() {
  return (
    <div>
      <Navbar active="" />
      <div className="container mx-auto max-w-7xl px-4 sm:px-6 lg:px-8">
        <section>
          <div className="grid grid-cols-1 lg:grid-cols-2 justify-center items-center py-14">
            <div className="justify-center items-center mt-8 lg:mt-0 order-2 lg:order-1">
              <h1 className="text-4xl lg:text-7xl font-bold text-gray-950">
                Tech Solution for{" "}
                <span className="text-[#FF84FC]">Your Business</span>
              </h1>
              <p className="py-4 lg:w-6/12 text-base">
                We can help you build your product, either it is ground up or
                refine your product.
              </p>
              <Link to="/form-build">
                <a
                  href="#"
                  className="inline-flex items-center border-transparent px-4 mt-1 py-2 rounded-md text-lg font-semibold text-white bg-[#47E0E2]"
                >
                  Let’s Build
                </a>
              </Link>
            </div>
            <div className="order-1 lg:ml-auto">
              <img
                className="w-full h-full lg:h-[480px] lg:w-[480px]"
                src="../assets/illustrations/ilus-1.png"
                alt=""
              />
            </div>
          </div>
        </section>
        <section>
          <div className="grid grid-cols-1 lg:grid-cols-2 justify-center items-center py-14">
            <div className="justify-center items-center">
              <img
                className="w-full h-full lg:h-[480px] lg:w-[480px]"
                src="../assets/illustrations/ilus-2.png"
                alt=""
              />
            </div>
            <div className="mt-12 lg:mt-0">
              <h1 className="text-2xl lg:text-4xl font-bold text-gray-950">
                Who Are We?
              </h1>
              <p className="text-base lg:text-lg lg:w-9/12 text-gray-950 mt-6">
                Our team have collaborated with many companies in building
                custom mobile and website app.{" "}
              </p>
              <p className="text-base lg:text-lg lg:w-9/12 text-gray-950 mt-6">
                We have a vision to help you (whether you’re corporate or
                start-up) to solve your IT problem.{" "}
              </p>
              <p className="text-base lg:text-lg lg:w-9/12 text-gray-950 mt-6">
                We work through collaboration, we are the extra hand to your
                team to help you achieve your business goal.
              </p>
            </div>
          </div>
        </section>
        <section>
          <div className="justify-center items-center py-14">
            <h1 className="text-2xl lg:text-4xl font-bold text-gray-950 text-center">
              How Can We Help You?
            </h1>
            <div className="grid grid-cols-1 lg:grid-cols-4 justify-center items-center py-4 mt-14 space-y-8 lg:space-y-0 lg:space-x-4">
              <div className="flex flex-col items-center justify-center border-[1px] border-gray-200 rounded-2xl p-6 h-[480px] lg:mb-0">
                <h1 className="text-2xl font-bold text-gray-950 ">Discover</h1>
                <img
                  className="w-[160px] h-[160px] my-5"
                  src="../assets/illustrations/il-service-1.png"
                  alt=""
                />
                <p className="text-base text-gray-950 text-center">
                  Starting from requirement gathering, we can help you establish
                  your ideas. Finding the best options for the problems you
                  have.
                </p>
                <div className="mt-4 flex flex-row flex-wrap justify-center">
                  <span className="border-[1px] px-4 py-1 rounded-full border-gray-950 text-gray-950 text-sm mr-2 mb-2">
                    Business Analyst
                  </span>
                  <span className="border-[1px] px-4 py-1 rounded-full border-gray-950 text-gray-950 text-sm mr-2 mb-2">
                    Roadmap
                  </span>
                  <span className="border-[1px] px-4 py-1 rounded-full border-gray-950 text-gray-950 text-sm mr-2 mb-2">
                    Strategy
                  </span>
                </div>
              </div>
              <div className="flex flex-col items-center justify-center border-[1px] border-gray-200 rounded-2xl p-6 h-[480px] lg:mb-0">
                <h1 className="text-2xl font-bold text-gray-950 ">Design</h1>
                <img
                  className="w-[160px] h-[160px] my-5"
                  src="../assets/illustrations/il-service-2.png"
                  alt=""
                />
                <p className="text-base text-gray-950 text-center">
                  We do research and planning for the UI and UX. Providing the
                  best quality of Wireframe, Prototype, and High Fidelity
                  Design.
                </p>
                <div className="mt-4 flex flex-row flex-wrap justify-center">
                  <span className="border-[1px] px-4 py-1 rounded-full border-gray-950 text-gray-950 text-sm mr-2 mb-2">
                    UI/UX
                  </span>
                  <span className="border-[1px] px-4 py-1 rounded-full border-gray-950 text-gray-950 text-sm mr-2 mb-2">
                    Wireframe
                  </span>
                  <span className="border-[1px] px-4 py-1 rounded-full border-gray-950 text-gray-950 text-sm mr-2 mb-2">
                    Research
                  </span>
                  <span className="border-[1px] px-4 py-1 rounded-full border-gray-950 text-gray-950 text-sm mr-2 mb-2">
                    Prototype
                  </span>
                </div>
              </div>
              <div className="flex flex-col items-center justify-center border-[1px] border-gray-200 rounded-2xl p-6 h-[480px] lg:mb-0">
                <h1 className="text-2xl font-bold text-gray-950 ">Build</h1>
                <img
                  className="w-[160px] h-[160px] my-5"
                  src="../assets/illustrations/il-service-3.png"
                  alt=""
                />
                <p className="text-base text-gray-950 text-center">
                  Build your product with modern technologies and best customer
                  experience, take every small detail seriously, and also
                  progress in a high pace.
                </p>
                <div className="mt-4 flex flex-row flex-wrap justify-center">
                  <span className="border-[1px] px-4 py-1 rounded-full border-gray-950 text-gray-950 text-sm mr-2 mb-2">
                    Website Development
                  </span>
                  <span className="border-[1px] px-4 py-1 rounded-full border-gray-950 text-gray-950 text-sm mr-2 mb-2">
                    Mobile App
                  </span>
                  <span className="border-[1px] px-4 py-1 rounded-full border-gray-950 text-gray-950 text-sm mr-2 mb-2">
                    Testing
                  </span>
                </div>
              </div>
              <div className="flex flex-col items-center justify-center border-[1px] border-gray-200 rounded-2xl p-6 h-[480px] lg:mb-0">
                <h1 className="text-2xl font-bold text-gray-950 ">Improve</h1>
                <img
                  className="w-[160px] h-[160px] my-5"
                  src="../assets/illustrations/il-service-4.png"
                  alt=""
                />
                <p className="text-base text-gray-950 text-center">
                  Refine your product or introduce a new one through iterative
                  design and development process. Ensuring your business never
                  goes down.
                </p>
                <div className="mt-4 flex flex-row flex-wrap justify-center">
                  <span className="border-[1px] px-4 py-1 rounded-full border-gray-950 text-gray-950 text-sm mr-2 mb-2">
                    System Maintenance
                  </span>
                  <span className="border-[1px] px-4 py-1 rounded-full border-gray-950 text-gray-950 text-sm mr-2 mb-2">
                    Update
                  </span>
                  <span className="border-[1px] px-4 py-1 rounded-full border-gray-950 text-gray-950 text-sm mr-2 mb-2">
                    MVP
                  </span>
                </div>
              </div>
            </div>
          </div>
        </section>
        <section>
          <div className="justify-center items-center py-14">
            <h1 className="text-2xl lg:text-4xl font-bold text-gray-950 text-center">
              Our Tech Stack
            </h1>
            <div className="grid grid-cols-3 lg:grid-cols-7 justify-center text-center items-center py-4 mt-14">
              <div>
                <img
                  className="object-contain"
                  src="../../assets/illustrations/google-cloud.png"
                  alt=""
                />
              </div>
              <div>
                <img
                  className="object-contain"
                  src="../../assets/illustrations/github.png"
                  alt=""
                />
              </div>
              <div>
                <img
                  className="object-contain"
                  src="../../assets/illustrations/wordpress.png"
                  alt=""
                />
              </div>
              <div>
                <img
                  className="object-contain"
                  src="../../assets/illustrations/java.png"
                  alt=""
                />
              </div>
              <div>
                <img
                  className="object-contain"
                  src="../../assets/illustrations/aws.png"
                  alt=""
                />
              </div>
              <div>
                <img
                  className="object-contain"
                  src="../../assets/illustrations/firebase.png"
                  alt=""
                />
              </div>
              <div>
                <img
                  className="object-contain"
                  src="../../assets/illustrations/flutter.png"
                  alt=""
                />
              </div>
              <div>
                <img
                  className="object-contain"
                  src="../../assets/illustrations/nodejs.png"
                  alt=""
                />
              </div>
              <div>
                <img
                  className="object-contain"
                  src="../../assets/illustrations/mysql.png"
                  alt=""
                />
              </div>
              <div>
                <img
                  className="object-contain"
                  src="../../assets/illustrations/reactjs.png"
                  alt=""
                />
              </div>
              <div>
                <img
                  className="object-contain"
                  src="../../assets/illustrations/php.png"
                  alt=""
                />
              </div>
              <div>
                <img
                  className="object-contain"
                  src="../../assets/illustrations/laravel.png"
                  alt=""
                />
              </div>
              <div>
                <img
                  className="object-contain"
                  src="../../assets/illustrations/go.png"
                  alt=""
                />
              </div>
              <div className="">
                <img
                  className="object-contain"
                  src="../../assets/illustrations/postfresql.png"
                  alt=""
                />
              </div>
            </div>
          </div>
        </section>
        <section>
          <div className="justify-center items-center lg:py-14">
            <h1 className="text-2xl lg:text-4xl font-bold text-gray-950 text-center">
              Our Featured Work
            </h1>
            <Carousel
              autoplay
              loop
              className="rounded-xl mt-8"
              navigation={({ setActiveIndex, activeIndex, length }) => (
                <div className="absolute bottom-4 left-2/4 z-50 flex -translate-x-2/4 gap-2">
                  {new Array(length).fill("").map((_, i) => (
                    <span
                      key={i}
                      className={`block h-1 cursor-pointer rounded-2xl transition-all content-[''] ${
                        activeIndex === i ? "bg-white w-8" : "bg-white/50 w-4"
                      }`}
                      onClick={() => setActiveIndex(i)}
                    />
                  ))}
                </div>
              )}
            >
              <img
                src="../assets/illustrations/carousel-2.png"
                alt="image 2"
                className="h-full w-full object-contain"
              />
              <img
                src="../assets/illustrations/carousel-1.png"
                alt="image 1"
                className="h-full w-full object-contain"
              />
              <img
                src="../assets/illustrations/carousel-3.png"
                alt="image 2"
                className="h-full w-full object-contain"
              />
              <img
                src="../assets/illustrations/carousel-4.png"
                alt="image 2"
                className="h-full w-full object-contain"
              />
              <img
                src="../assets/illustrations/carousel-5.png"
                alt="image 2"
                className="h-full w-full object-contain"
              />
              <img
                src="../assets/illustrations/carousel-6.png"
                alt="image 2"
                className="h-full w-full object-contain"
              />
              <img
                src="../assets/illustrations/carousel-7.png"
                alt="image 2"
                className="h-full w-full object-contain"
              />
              <img
                src="../assets/illustrations/carousel-8.png"
                alt="image 2"
                className="h-full w-full object-contain"
              />
            </Carousel>
          </div>
        </section>
      </div>
      <section>
        <div className="slider py-14 lg:py-0">
          <div className="slide-track justify-center items-center lg:space-x-8">
            <div className="slide justify-center items-center">
              <img
                className="h-16 m-auto justify-center items-center"
                src="../assets/illustrations/patner-1.png"
                alt=""
              />
            </div>
            <div className="slide justify-center items-center">
              <img
                className="h-16 m-auto justify-center items-center"
                src="../assets/illustrations/patner-2.png"
                alt=""
              />
            </div>
            <div className="slide justify-center items-center">
              <img
                className="h-16 m-auto justify-center items-center"
                src="../assets/illustrations/patner-3.png"
                alt=""
              />
            </div>
            <div className="slide justify-center items-center">
              <img
                className="h-16 m-auto justify-center items-center"
                src="../assets/illustrations/patner-4.png"
                alt=""
              />
            </div>
            <div className="slide justify-center items-center">
              <img
                className="h-16 m-auto justify-center items-center"
                src="../assets/illustrations/patner-5.png"
                alt=""
              />
            </div>
            <div className="slide justify-center items-center">
              <img
                className="h-16 m-auto justify-center items-center"
                src="../assets/illustrations/patner-6.png"
                alt=""
              />
            </div>
            <div className="slide justify-center items-center">
              <img
                className="h-16 m-auto justify-center items-center"
                src="../assets/illustrations/patner-7.png"
                alt=""
              />
            </div>
            <div className="slide justify-center items-center">
              <img
                className="h-16 m-auto justify-center items-center"
                src="../assets/illustrations/patner-1.png"
                alt=""
              />
            </div>
            <div className="slide justify-center items-center">
              <img
                className="h-16 m-auto justify-center items-center"
                src="../assets/illustrations/patner-2.png"
                alt=""
              />
            </div>
            <div className="slide justify-center items-center">
              <img
                className="h-16 m-auto justify-center items-center"
                src="../assets/illustrations/patner-3.png"
                alt=""
              />
            </div>
            <div className="slide justify-center items-center">
              <img
                className="h-16 m-auto justify-center items-center"
                src="../assets/illustrations/patner-4.png"
                alt=""
              />
            </div>
            <div className="slide justify-center items-center">
              <img
                className="h-16 m-auto justify-center items-center"
                src="../assets/illustrations/patner-5.png"
                alt=""
              />
            </div>
            <div className="slide justify-center items-center">
              <img
                className="h-16 m-auto justify-center items-center"
                src="../assets/illustrations/patner-6.png"
                alt=""
              />
            </div>
            <div className="slide justify-center items-center">
              <img
                className="h-16 m-auto justify-center items-center"
                src="../assets/illustrations/patner-7.png"
                alt=""
              />
            </div>
          </div>
        </div>
      </section>
      <Footer />
    </div>
  );
}

export default Home;
