import { Fragment, useState } from "react";
import { Listbox, Transition } from "@headlessui/react";
import { CheckIcon, ChevronUpDownIcon } from "@heroicons/react/20/solid";
import Navbar from "../../components/navbar/navbar";
import Footer from "../../components/footer/footer";
import { ChevronDownIcon } from "@heroicons/react/24/outline";

function classNames(...classes: any[]): string {
  return classes.filter(Boolean).join(" ");
}

const phone = [
  { id: 1, name: "US(United States and Canada) +1" },
  { id: 2, name: "UK(United Kingdom) +1" },
  { id: 3, name: "ID(Indonesia) +62" },
  { id: 4, name: "AUS(Australia) +61" },
  { id: 5, name: "CHI(China) +82" },
  { id: 6, name: "IND(India) +91" },
  { id: 7, name: "MEX(Mexico) +52" },
  { id: 8, name: "FRA(France) +33" },
  { id: 9, name: "GER(Germany) +49" },
  { id: 10, name: "JAP(Japan) +81" },
  { id: 11, name: "RUSS(Russia) 7" },
  { id: 12, name: "BRA(Brazil) +55" },
  { id: 13, name: "ITA(Italy) +39" },
  { id: 14, name: "SPA(Spain) +34" },
  { id: 15, name: "SK(South Korea) +82" },
  { id: 16, name: "NZ(New Zealand) +64" },
  { id: 17, name: "SWE(Sweden) +46" },
  { id: 18, name: "UAE(United Arab Emirates) +971" },
  { id: 19, name: "SIN(Singapore) +65" },
  { id: 20, name: "SWZL(Switzerland) +41" },
];

function FormBuild() {
  const [selected, setSelected] = useState(phone[2]);
  return (
    <div>
      <Navbar active="" />
      <div className="container mx-auto max-w-7xl px-4 sm:px-6 lg:px-8">
        <section>
          <div className="justify-center items-center py-14">
            <h1 className="text-4xl text-center lg:text-4xl font-black text-gray-950">
              Let’s Get Started!
            </h1>
            <div className="my-14 lg:px-44">
              <form action="">
                <input
                  type="text"
                  placeholder="Name"
                  className="w-full border-0 border-b-2 p-3 px-4 text-base font-light mb-6  focus:border-b-2 focus:border-b-[#47E0E2] focus:ring-0"
                />
                <input
                  type="text"
                  placeholder="Company Name"
                  className="w-full border-0 border-b-2 p-3 px-4 text-base font-light mb-6  focus:border-b-2 focus:border-b-[#47E0E2] focus:ring-0"
                />
                <input
                  type="text"
                  placeholder="Email"
                  className="w-full border-0 border-b-2 p-3 px-4 text-base font-light mb-6  focus:border-b-2 focus:border-b-[#47E0E2] focus:ring-0"
                />
                <div className="flex items-center justify-center space-x-4">
                  <Listbox value={selected} onChange={setSelected}>
                    {({ open }) => (
                      <>
                        <div className="relative -top-3">
                          <Listbox.Button className="relative w-24 lg:w-auto cursor-default bg-white pl-3 border-b-2 pr-10 text-left text-gray-900 ring-gray-300 focus:outline-none focus:ring-[#FF84FC] sm:text-sm sm:leading-6 p-3">
                            <span className="block truncate">
                              {selected.name}
                            </span>
                            <span className="pointer-events-none absolute inset-y-0 right-0 flex items-center pr-2">
                              <ChevronDownIcon
                                className="h-5 w-5 text-gray-400"
                                aria-hidden="true"
                              />
                            </span>
                          </Listbox.Button>
                          <Transition
                            show={open}
                            as={Fragment}
                            leave="transition ease-in duration-100"
                            leaveFrom="opacity-100"
                            leaveTo="opacity-0"
                          >
                            <Listbox.Options className="absolute z-10 mt-1 max-h-60 w-auto lg:w-72 overflow-auto rounded-md bg-white py-1 text-base shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none sm:text-sm">
                              {phone.map((phone) => (
                                <Listbox.Option
                                  key={phone.id}
                                  className={({ active }) =>
                                    classNames(
                                      active
                                        ? "bg-[#FF84FC] text-white"
                                        : "text-gray-900",
                                      "relative cursor-default select-none py-2 pl-8 pr-4"
                                    )
                                  }
                                  value={phone}
                                >
                                  {({ selected, active }) => (
                                    <>
                                      <span
                                        className={classNames(
                                          selected
                                            ? "font-semibold"
                                            : "font-normal",
                                          "block truncate"
                                        )}
                                      >
                                        {phone.name}
                                      </span>

                                      {selected ? (
                                        <span
                                          className={classNames(
                                            active
                                              ? "text-white"
                                              : "text-indigo-600",
                                            "absolute inset-y-0 left-0 flex items-center pl-1.5"
                                          )}
                                        >
                                          <CheckIcon
                                            className="h-5 w-5"
                                            aria-hidden="true"
                                          />
                                        </span>
                                      ) : null}
                                    </>
                                  )}
                                </Listbox.Option>
                              ))}
                            </Listbox.Options>
                          </Transition>
                        </div>
                      </>
                    )}
                  </Listbox>
                  <input
                    type="text"
                    placeholder="Phone"
                    className="w-full border-0 border-b-2 p-3 px-4 text-base font-light mb-6  focus:border-b-2 focus:border-b-[#47E0E2] focus:ring-0"
                  />
                </div>
                <div>
                  <p className="text-sm">
                    Choose Enquiry: (you can choose more than one)
                  </p>
                  <div className="flex items-center flex-wrap my-4 w-6/12">
                    <div className="flex items-center space-x-2 mr-4 mb-4">
                      <input
                        id="consultation"
                        aria-describedby="candidates-description"
                        name="consultation"
                        type="checkbox"
                        className="h-4 w-4 focus:border-[#47E0E2] focus:ring-0 rounded border-gray-300 text-[#47E0E2] focus:ring-[#47E0E2] focus:border-transparent"
                      />
                      <p className="relative top-[1.5px] text-sm text-gray-800">
                        Consultation
                      </p>
                    </div>
                    <div className="flex items-center space-x-2 mr-4 mb-4">
                      <input
                        id="consultation"
                        aria-describedby="candidates-description"
                        name="consultation"
                        type="checkbox"
                        className="h-4 w-4 focus:border-[#47E0E2] focus:ring-0 rounded border-gray-300 text-[#47E0E2] focus:ring-[#47E0E2] focus:border-transparent"
                      />
                      <p className="relative top-[1.5px] text-sm text-gray-800">
                        UI/UX Design
                      </p>
                    </div>
                    <div className="flex items-center space-x-2 mr-4 mb-4">
                      <input
                        id="consultation"
                        aria-describedby="candidates-description"
                        name="consultation"
                        type="checkbox"
                        className="h-4 w-4 focus:border-[#47E0E2] focus:ring-0 rounded border-gray-300 text-[#47E0E2] focus:ring-[#47E0E2] focus:border-transparent"
                      />
                      <p className="relative top-[1.5px] text-sm text-gray-800">
                        Web Development
                      </p>
                    </div>
                    <div className="flex items-center space-x-2 mr-4 mb-4">
                      <input
                        id="consultation"
                        aria-describedby="candidates-description"
                        name="consultation"
                        type="checkbox"
                        className="h-4 w-4 focus:border-[#47E0E2] focus:ring-0 rounded border-gray-300 text-[#47E0E2] focus:ring-[#47E0E2] focus:border-transparent"
                      />
                      <p className="relative top-[1.5px] text-sm text-gray-800">
                        Mobile Development
                      </p>
                    </div>
                    <div className="flex items-center space-x-2 mr-4 mb-4">
                      <input
                        id="consultation"
                        aria-describedby="candidates-description"
                        name="consultation"
                        type="checkbox"
                        className="h-4 w-4 focus:border-[#47E0E2] focus:ring-0 rounded border-gray-300 text-[#47E0E2] focus:ring-[#47E0E2] focus:border-transparent"
                      />
                      <p className="relative top-[1.5px] text-sm text-gray-800">
                        Support
                      </p>
                    </div>
                  </div>
                </div>
                <div className="mt-2">
                  <textarea
                    rows={4}
                    name="comment"
                    id="comment"
                    className="w-full rounded-lg border-2 p-3 px-4 text-base font-light mb-6  focus:border-b-2 focus:border-[#47E0E2] focus:ring-0"
                    defaultValue={""}
                    placeholder="Write your message here"
                  />
                </div>
                <div className="flex items-center justify-center mt-4">
                  <a
                    href="#"
                    className="inline-flex text-center items-center border-transparent px-4 mt-1 py-2 self-center rounded-md text-lg font-semibold text-white bg-[#47E0E2]"
                  >
                    Submit
                  </a>
                </div>
              </form>
            </div>
          </div>
        </section>
      </div>
      <Footer />
    </div>
  );
}

export default FormBuild;
