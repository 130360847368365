import React from "react";
import { Carousel } from "@material-tailwind/react";
import Navbar from "../../components/navbar/navbar";
import Footer from "../../components/footer/footer";
import {
  Tabs,
  TabsHeader,
  TabsBody,
  Tab,
  TabPanel,
} from "@material-tailwind/react";
import { ChevronRightIcon } from "@heroicons/react/24/outline";
import { Link } from "react-router-dom";

interface Project {
  id: number;
  img: string;
  name: string;
  link?: string;
}

interface ItemLink {
  label: string;
  value: string;
  project: Project[];
}

type Item = ItemLink;

const data: Item[] = [
  {
    label: "All",
    value: "All",
    project: [
      {
        id: 1,
        img: "../../assets/illustrations/carousel-2.png",
        name: "Mobile App Rekosistem x Astra ",
        link: "/work/1-astra",
      },
      {
        id: 2,
        img: "../../assets/illustrations/carousel-3.png",
        name: "Mobile App Wilopo Cargo",
        link: "/work/3-wilopo",
      },
      {
        id: 3,
        img: "../../assets/illustrations/carousel-1.png",
        name: "Mobile App Diary Bunda",
        link: "/work/2-diary-bunda",
      },
      {
        id: 4,
        img: "../../assets/illustrations/carousel-4.png",
        name: "Mobile App ORARI",
        link: "/work/5-orari",
      },
      {
        id: 5,
        img: "../../assets/illustrations/carousel-6.png",
        name: "Mobile App Simanis Banten",
        link: "/work/4-simanis",
      },
      {
        id: 6,
        img: "../../assets/illustrations/carousel-7.png",
        name: "Design Template - Restaurant Order Delivery",
        link: "/work/6-restaurant",
      },
      {
        id: 7,
        img: "../../assets/illustrations/carousel-8.png",
        name: "Design Template - Beauty Commerce",
        link: "/work/7-beauty",
      },
      {
        id: 8,
        img: "../../assets/illustrations/carousel-5.png",
        name: "Website Mokotoy Cat Furr-niture",
        link: "/work/8-mokotoy",
      },
    ],
  },
  {
    label: "Website",
    value: "Website",
    project: [
      {
        id: 1,
        img: "../../assets/illustrations/carousel-7.png",
        name: "Design Template - Restaurant Order Delivery",
        link: "/work/6-restaurant",
      },
      {
        id: 2,
        img: "../../assets/illustrations/carousel-8.png",
        name: "Design Template - Beauty Commerce",
        link: "/work/7-beauty",
      },

      {
        id: 3,
        img: "../../assets/illustrations/carousel-5.png",
        name: "Website Mokotoy Cat Furr-niture",
        link: "/work/8-mokotoy",
      },
    ],
  },
  {
    label: "Mobile App",
    value: "Mobile App",
    project: [
      {
        id: 1,
        img: "../../assets/illustrations/carousel-2.png",
        name: "Mobile App Rekosistem x Astra ",
        link: "/work/1-astra",
      },
      {
        id: 2,
        img: "../../assets/illustrations/carousel-3.png",
        name: "Mobile App Wilopo Cargo",
        link: "/work/3-wilopo",
      },
      {
        id: 3,
        img: "../../assets/illustrations/carousel-1.png",
        name: "Mobile App Diary Bunda",
        link: "/work/2-diary-bunda",
      },
      {
        id: 4,
        img: "../../assets/illustrations/carousel-4.png",
        name: "Mobile App ORARI",
        link: "/work/5-orari",
      },
      // {
      //   id: 5,
      //   img: "../../assets/illustrations/carousel-6.png",
      //   name: "Mobile App Simanis Banten",
      //   link: "/work/4-simanis",
      // },
    ],
  },
  {
    label: "Website App",
    value: "Website App",
    project: [
      // {
      //   id: 1,
      //   img: "../../assets/illustrations/carousel-7.png",
      //   name: "Design Template - Restaurant Order Delivery",
      //   link: "/work/6-restaurant",
      // },
      // {
      //   id: 2,
      //   img: "../../assets/illustrations/carousel-8.png",
      //   name: "Design Template - Beauty Commerce",
      //   link: "/work/7-beauty",
      // },
      {
        id: 3,
        img: "../../assets/illustrations/carousel-5.png",
        name: "Website Mokotoy Cat Furr-niture",
        link: "/work/8-mokotoy",
      },
    ],
  },
  {
    label: "Design",
    value: "Design",
    project: [
      // {
      //   id: 1,
      //   img: "../../assets/illustrations/carousel-7.png",
      //   name: "Design Template - Restaurant Order Delivery",
      //   link: "/work/6-restaurant",
      // },
      // {
      //   id: 2,
      //   img: "../../assets/illustrations/carousel-8.png",
      //   name: "Design Template - Beauty Commerce",
      //   link: "/work/7-beauty",
      // },
      {
        id: 3,
        img: "../../assets/illustrations/carousel-5.png",
        name: "Website Mokotoy Cat Furr-niture",
        link: "/work/8-mokotoy",
      },
    ],
  },
];

function Project() {
  const [activeTab, setActiveTab] = React.useState("all");
  return (
    <div>
      <Navbar active="project" />
      <div className="container mx-auto max-w-7xl px-4 sm:px-6 lg:px-8 ">
        <section>
          <div className="grid grid-cols-1 justify-center items-center lg:py-14">
            <div className="justify-center items-center mt-8 lg:mt-0 ">
              <h1 className="text-4xl lg:text-7xl font-black text-gray-950 lg:w-10/12">
                Works We Have Done{" "}
                <span className="text-[#FF84FC]">with Our Partners</span>
              </h1>
            </div>
          </div>
        </section>
        <section>
          <div className="mt-8">
            <Tabs value="All">
              <TabsHeader
                className="bg-white space-x-8 overflow-x-auto scrollbar-hide w-full"
                indicatorProps={{
                  className:
                    "shadow-none border-b-4 rounded-none border-[#74F3FB]",
                }}
              >
                {data.map(({ label, value }) => (
                  <Tab
                    className="bg-white pb-2 lg:w-auto flex-nowrap"
                    key={value}
                    value={value}
                  >
                    <div className="lg:w-auto flex-nowrap w-24">{label}</div>
                  </Tab>
                ))}
              </TabsHeader>
              <TabsBody>
                {data.map(({ value, project }) => (
                  <TabPanel
                    className="grid grid-cols-1 lg:grid-cols-2 "
                    key={value}
                    value={value}
                  >
                    {project?.map((item) => {
                      return (
                        <a href={item.link}>
                          <div className="rounded-2xl overflow-hidden border-2 m-2 cursor-pointer">
                            <div>
                              <img
                                className="hover:scale-110 transition transform duration-200 ease-out"
                                src={item.img}
                                alt=""
                              />
                            </div>
                            <div className="flex items-center justify-between p-4">
                              <h1 className="text-sm lg:text-base font-black">
                                {item.name}
                              </h1>
                              <a href="">
                                <ChevronRightIcon className="h-6 w-6" />
                              </a>
                            </div>
                          </div>
                        </a>
                      );
                    })}
                  </TabPanel>
                ))}
              </TabsBody>
            </Tabs>
          </div>
        </section>
      </div>
      <Footer />
    </div>
  );
}

export default Project;
