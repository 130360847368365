import React from "react";
import { Carousel } from "@material-tailwind/react";
import Navbar from "../../components/navbar/navbar";
import Footer from "../../components/footer/footer";

interface Props {
  phoneNumber: string;
  whatsappLink: string;
}

function Service() {

  const phoneNumber = '081317473439';
  const whatsappLink = `https://wa.me/${phoneNumber}`;

  return (
    <div>
      <Navbar active="service" />
      <div className="container mx-auto max-w-7xl px-4 sm:px-6 lg:px-8">
        <section>
          <div className="grid grid-cols-1 justify-center items-center lg:py-14">
            <div className="justify-center items-center mt-8 lg:mt-0 ">
              <h1 className="text-4xl lg:text-7xl font-black text-gray-950 w-9/12">
                We have collaborated{" "}
                <span className="text-[#FF84FC]">
                  with Start-up, SMEs & Enterprise
                </span>
              </h1>
              <p className="py-4 lg:w-6/12 text-xl">
                We can help you build your product, either it is web or mobile
                development. We help you build your business ideas or improve
                the current workflow.
              </p>
            </div>
          </div>
        </section>
        <section>
          <div className="justify-center items-center lg:py-14">
            <h1 className="text-2xl lg:text-4xl font-black text-gray-950 text-center mt-14 lg:mt-0">
              What We Offer?
            </h1>
            <div className="grid grid-cols-1 lg:grid-cols-4 justify-center items-center py-4 mt-14 space-y-8 lg:space-y-0 lg:space-x-4">
              <div className="flex flex-col items-center justify-center border-[1px] border-gray-200 rounded-2xl p-6 h-[480px] lg:mb-0">
                <h1 className="text-2xl font-bold text-gray-950 ">Discover</h1>
                <img
                  className="w-[160px] h-[160px] my-5"
                  src="../assets/illustrations/il-service-1.png"
                  alt=""
                />
                <p className="text-base text-gray-950 text-center">
                  Starting from requirement gathering, we can help you establish
                  your ideas. Finding the best options for the problems you
                  have.
                </p>
                <div className="mt-4 flex flex-row flex-wrap justify-center">
                  <span className="border-[1px] px-4 py-1 rounded-full border-gray-950 text-gray-950 text-sm mr-2 mb-2">
                    Business Analyst
                  </span>
                  <span className="border-[1px] px-4 py-1 rounded-full border-gray-950 text-gray-950 text-sm mr-2 mb-2">
                    Roadmap
                  </span>
                  <span className="border-[1px] px-4 py-1 rounded-full border-gray-950 text-gray-950 text-sm mr-2 mb-2">
                    Strategy
                  </span>
                </div>
              </div>
              <div className="flex flex-col items-center justify-center border-[1px] border-gray-200 rounded-2xl p-6 h-[480px] lg:mb-0">
                <h1 className="text-2xl font-bold text-gray-950 ">Design</h1>
                <img
                  className="w-[160px] h-[160px] my-5"
                  src="../assets/illustrations/il-service-2.png"
                  alt=""
                />
                <p className="text-base text-gray-950 text-center">
                  We do research and planning for the UI and UX. Providing the
                  best quality of Wireframe, Prototype, and High Fidelity
                  Design.
                </p>
                <div className="mt-4 flex flex-row flex-wrap justify-center">
                  <span className="border-[1px] px-4 py-1 rounded-full border-gray-950 text-gray-950 text-sm mr-2 mb-2">
                    UI/UX
                  </span>
                  <span className="border-[1px] px-4 py-1 rounded-full border-gray-950 text-gray-950 text-sm mr-2 mb-2">
                    Wireframe
                  </span>
                  <span className="border-[1px] px-4 py-1 rounded-full border-gray-950 text-gray-950 text-sm mr-2 mb-2">
                    Research
                  </span>
                  <span className="border-[1px] px-4 py-1 rounded-full border-gray-950 text-gray-950 text-sm mr-2 mb-2">
                    Prototype
                  </span>
                </div>
              </div>
              <div className="flex flex-col items-center justify-center border-[1px] border-gray-200 rounded-2xl p-6 h-[480px] lg:mb-0">
                <h1 className="text-2xl font-bold text-gray-950 ">Build</h1>
                <img
                  className="w-[160px] h-[160px] my-5"
                  src="../assets/illustrations/il-service-3.png"
                  alt=""
                />
                <p className="text-base text-gray-950 text-center">
                  Build your product with modern technologies and best customer
                  experience, take every small detail seriously, and also
                  progress in a high pace.
                </p>
                <div className="mt-4 flex flex-row flex-wrap justify-center">
                  <span className="border-[1px] px-4 py-1 rounded-full border-gray-950 text-gray-950 text-sm mr-2 mb-2">
                    Website Development
                  </span>
                  <span className="border-[1px] px-4 py-1 rounded-full border-gray-950 text-gray-950 text-sm mr-2 mb-2">
                    Mobile App
                  </span>
                  <span className="border-[1px] px-4 py-1 rounded-full border-gray-950 text-gray-950 text-sm mr-2 mb-2">
                    Testing
                  </span>
                </div>
              </div>
              <div className="flex flex-col items-center justify-center border-[1px] border-gray-200 rounded-2xl p-6 h-[480px] lg:mb-0">
                <h1 className="text-2xl font-bold text-gray-950 ">Improve</h1>
                <img
                  className="w-[160px] h-[160px] my-5"
                  src="../assets/illustrations/il-service-4.png"
                  alt=""
                />
                <p className="text-base text-gray-950 text-center">
                  Refine your product or introduce a new one through iterative
                  design and development process. Ensuring your business never
                  goes down.
                </p>
                <div className="mt-4 flex flex-row flex-wrap justify-center">
                  <span className="border-[1px] px-4 py-1 rounded-full border-gray-950 text-gray-950 text-sm mr-2 mb-2">
                    System Maintenance
                  </span>
                  <span className="border-[1px] px-4 py-1 rounded-full border-gray-950 text-gray-950 text-sm mr-2 mb-2">
                    Update
                  </span>
                  <span className="border-[1px] px-4 py-1 rounded-full border-gray-950 text-gray-950 text-sm mr-2 mb-2">
                    MVP
                  </span>
                </div>
              </div>
            </div>
            <h1 className="text-2xl lg:text-4xl font-black text-gray-950 text-center mt-24">
            Collaborate with Us!
            </h1>
            <div className="flex items-center justify-center mt-14">
            <a
                href="https://wa.me/+6281317473439"
                className="inline-flex text-center items-center border-transparent px-4 mt-1 py-2 self-center rounded-md text-lg font-semibold text-white bg-[#47E0E2]"
              >
                Contact Us
              </a>
            </div>
          </div>
        </section>
      </div>
      <Footer />
    </div>
  );
}

export default Service;
