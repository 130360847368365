import React from "react";
import { Carousel } from "@material-tailwind/react";
import Navbar from "../../../components/navbar/navbar";
import Footer from "../../../components/footer/footer";

function Beauty() {
  return (
    <div>
      <Navbar active="" />
      <div className="container mx-auto max-w-7xl px-4 sm:px-6 lg:px-8">
        <section>
          <div className="grid grid-cols-1 justify-center items-center lg:py-14">
            <div className="justify-center items-center mt-8 lg:mt-0 ">
              <h1 className="text-4xl lg:text-7xl font-black text-gray-950 lg:w-10/12">
                Works We Have Done{" "}
                <span className="text-[#FF84FC]">Design Beauty Commerce</span>
              </h1>
            </div>
          </div>
        </section>
        <section>
          <div className="justify-center items-center lg:py-14">
            <div className="grid grid-cols-1 lg:grid-cols-2 justify-center items-center py-4 mt-14 space-y-8 lg:space-y-0 lg:space-x-4">
              <div>
                <img
                  className="w-full h-full lg:h-[450px] lg:w-[450px]"
                  src="../assets/illustrations/work-beauty.png"
                  alt=""
                />
              </div>
              <div>
                <h1 className="font-black text-3xl">
                  Design Template Beauty Commerce
                </h1>
                <p className="mt-4 lg:text-xl">
                  We made interactive design template of E-commerce for mobile
                  app and various device view for website.
                </p>
                <p className="mt-4 lg:text-xl">
                  This beauty e-commerce template contain feature such as beauty
                  review, product showcase, product review and many more.
                </p>
              </div>
            </div>
            <div className="flex-row space-y-6">
              <img
                className="rounded-xl w-full lg:rounded-3xl object-cover"
                src="../assets/illustrations/carousel-8.png"
                alt=""
              />
              <img
                className="rounded-xl w-full lg:rounded-3xl object-cover"
                src="../assets/illustrations/carousel-10.png"
                alt=""
              />
            </div>
            <h1 className="text-2xl lg:text-4xl font-black text-gray-950 text-center mt-24">
              Collaborate with Us!
            </h1>
            <div className="flex items-center justify-center mt-14">
            <a
                href="https://wa.me/+6281317473439"
                className="inline-flex text-center items-center border-transparent px-4 mt-1 py-2 self-center rounded-md text-lg font-semibold text-white bg-[#47E0E2]"
              >
                Contact Us
              </a>
            </div>
          </div>
        </section>
      </div>
      <Footer />
    </div>
  );
}

export default Beauty;
