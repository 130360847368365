import { Fragment } from "react";
import { Disclosure, Menu, Transition } from "@headlessui/react";
import { Bars3Icon, BellIcon, XMarkIcon } from "@heroicons/react/24/outline";
import { Link } from "react-router-dom";

function classNames(...classes: any[]) {
  return classes.filter(Boolean).join(" ");
}

type Props = {
  active: string;
};

function Navbar({ active }: Props) {
  return (
    <Disclosure as="nav" className="bg-white shadow sticky top-0 z-50">
      {({ open }) => (
        <>
          <div className="mx-auto max-w-7xl px-4 sm:px-6 lg:px-8">
            <div className="flex h-16 justify-between sticky items-center">
              <div className="flex flex-shrink-0 items-center">
                <Link to="/">
                  <img
                    className="block h-10 w-auto lg:hidden"
                    src="../../assets/illustrations/logo.png"
                    alt="Your Company"
                  />
                  <img
                    className="hidden h-10 w-auto lg:block"
                    src="../../assets/illustrations/logo.png"
                    alt="Your Company"
                  />
                </Link>
              </div>
              <div className="hidden sm:ml-6 sm:flex sm:space-x-8">
                {active === "service" ? (
                  <Link
                    className="inline-flex items-center px-1 pt-1 text-base font-bold text-gray-900 hover:border-gray-300 hover:text-gray-700 border-b-4 border-b-[#74F3FB]"
                    to="/service"
                  >
                    Services
                  </Link>
                ) : (
                  <Link
                    className="inline-flex items-center px-1 pt-1 text-base font-medium text-gray-500 hover:text-gray-700"
                    to="/service"
                  >
                    Services
                  </Link>
                )}
                {active === "project" ? (
                  <Link
                    className="inline-flex items-center px-1 pt-1 text-base font-bold text-gray-900 hover:border-gray-300 hover:text-gray-700 border-b-4 border-b-[#74F3FB]"
                    to="/project"
                  >
                    Projects
                  </Link>
                ) : (
                  <Link
                    className="inline-flex items-center px-1 pt-1 text-base font-medium text-gray-500 hover:text-gray-700"
                    to="/project"
                  >
                    Projects
                  </Link>
                )}
                {active === "career" ? (
                  <Link
                    className="inline-flex items-center px-1 pt-1 text-base font-bold text-gray-900 hover:border-gray-300 hover:text-gray-700 border-b-4 border-b-[#74F3FB]"
                    to="/career"
                  >
                    Career
                  </Link>
                ) : (
                  <Link
                    className="inline-flex items-center px-1 pt-1 text-base font-medium text-gray-500 hover:text-gray-700"
                    to="/career"
                  >
                    Career
                  </Link>
                )}
                {active === "about-us" ? (
                  <Link
                    className="inline-flex items-center px-1 pt-1 text-base font-bold text-gray-900 hover:border-gray-300 hover:text-gray-700 border-b-4 border-b-[#74F3FB]"
                    to="/about-us"
                  >
                    About Us
                  </Link>
                ) : (
                  <Link
                    className="inline-flex items-center px-1 pt-1 text-base font-medium text-gray-500 hover:text-gray-700"
                    to="/about-us"
                  >
                    About Us
                  </Link>
                )}
              </div>
              <div className="hidden sm:ml-6 sm:flex sm:items-center">
              <a
                href="https://wa.me/+6281317473439"
                className="inline-flex text-center items-center border-transparent px-4 mt-1 py-2 self-center rounded-md text-sm font-semibold text-white bg-[#47E0E2]"
              >
                Contact Us
              </a>
              </div>
              <div className="-mr-2 flex items-center sm:hidden">
                {/* Mobile menu button */}
                <Disclosure.Button className="inline-flex items-center justify-center rounded-md p-2 text-gray-400 hover:bg-gray-100 hover:text-gray-500 focus:outline-none focus:ring-2 focus:ring-inset focus:ring-[#47E0E2]">
                  <span className="sr-only">Open main menu</span>
                  {open ? (
                    <XMarkIcon className="block h-6 w-6" aria-hidden="true" />
                  ) : (
                    <Bars3Icon className="block h-6 w-6" aria-hidden="true" />
                  )}
                </Disclosure.Button>
              </div>
            </div>
          </div>
          <Disclosure.Panel className="sm:hidden absolute w-full bg-white z-50 h-screen">
            <div className="space-y-1 pb-3 pt-2">
              {active === "service" ? (
                <Link
                  className="block border-l-4 border-[#47E0E2] bg-light-blue-50 py-2 pl-3 pr-4 text-base font-medium"
                  to="/service"
                >
                  Services
                </Link>
              ) : (
                <Link
                  className="block border-l-4 border-transparent py-2 pl-3 pr-4 text-base font-medium text-gray-500 hover:border-gray-300 hover:bg-gray-50 hover:text-gray-700"
                  to="/service"
                >
                  Services
                </Link>
              )}
              {active === "project" ? (
                <Link
                  className="block border-l-4 border-[#47E0E2] bg-light-blue-50 py-2 pl-3 pr-4 text-base font-medium"
                  to="/project"
                >
                  Project
                </Link>
              ) : (
                <Link
                  className="block border-l-4 border-transparent py-2 pl-3 pr-4 text-base font-medium text-gray-500 hover:border-gray-300 hover:bg-gray-50 hover:text-gray-700"
                  to="/project"
                >
                  Projects
                </Link>
              )}
              {active === "career" ? (
                <Link
                  className="block border-l-4 border-[#47E0E2] bg-light-blue-50 py-2 pl-3 pr-4 text-base font-medium"
                  to="/career"
                >
                  Career
                </Link>
              ) : (
                <Link
                  className="block border-l-4 border-transparent py-2 pl-3 pr-4 text-base font-medium text-gray-500 hover:border-gray-300 hover:bg-gray-50 hover:text-gray-700"
                  to="/career"
                >
                  Career
                </Link>
              )}
              {active === "about-us" ? (
                <Link
                  className="block border-l-4 border-[#47E0E2] bg-light-blue-50 py-2 pl-3 pr-4 text-base font-medium"
                  to="/about-us"
                >
                  About Us
                </Link>
              ) : (
                <Link
                  className="block border-l-4 border-transparent py-2 pl-3 pr-4 text-base font-medium text-gray-500 hover:border-gray-300 hover:bg-gray-50 hover:text-gray-700"
                  to="/about-us"
                >
                  About Us
                </Link>
              )}
              <div className="ml-3 lg:hidden sm:ml-6 sm:flex sm:items-center">
              <a
                href="https://wa.me/+6281317473439"
                className="inline-flex text-center items-center border-transparent px-4 mt-1 py-2 self-center rounded-md text-sm font-semibold text-white bg-[#47E0E2]"
              >
                Contact Us
              </a>
              </div>
            </div>
          </Disclosure.Panel>
        </>
      )}
    </Disclosure>
  );
}

export default Navbar;
