import Home from "./pages/home/home";
import Service from "./pages/service/service";
import AboutUs from "./pages/about-us/about-us";
import Career from "./pages/career/career";
import Project from "./pages/project/project";
import React, { useEffect } from "react";
import { BrowserRouter, Link, Route, Routes } from "react-router-dom";
import FormBuild from "./pages/form-build/form-build";
import Astra from "./pages/work/astra/astra";
import DiaryBunda from "./pages/work/diary-bunda/diary-bunda";
import Simanis from "./pages/work/simanis/simanis";
import Mokotoy from "./pages/work/mokotoy/mokotoy";
import Orari from "./pages/work/orari/orari";
import Wilopo from "./pages/work/wilopo/wilopo";
import Restaurant from "./pages/work/restaurant/restaurant";
import Beauty from "./pages/work/beauty/beauty"

function App() {
  useEffect(() => {
    document.title = "Znitech";
  }, []);
  return (
    <BrowserRouter>
      <Routes>
        <Route path="/" element={<Home />} />
        <Route path="/service" element={<Service />} />
        <Route path="/project" element={<Project />} />
        <Route path="/career" element={<Career />} />
        <Route path="/about-us" element={<AboutUs />} />
        <Route path="/form-build" element={<FormBuild />} />
      </Routes>
      <Routes>
        <Route path="/work/8-mokotoy" element={<Mokotoy />} />
        <Route path="/work/7-beauty" element={<Beauty />} />
        <Route path="/work/6-restaurant" element={<Restaurant />} />
        <Route path="/work/5-orari" element={<Orari />} />
        <Route path="/work/4-simanis" element={<Simanis />} />
        <Route path="/work/3-wilopo" element={<Wilopo />} />
        <Route path="/work/2-diary-bunda" element={<DiaryBunda />} />
        <Route path="/work/1-astra" element={<Astra />} />
      </Routes>
    </BrowserRouter>
  );
}

export default App;
