import React from "react";
import { Link } from "react-router-dom";

function Footer() {
  return (
    <>
      <div className="bg-white border-t-[1px] mt-14">
        <div className="mx-auto max-w-7xl px-4 sm:px-6 lg:px-8 mt-8 py-8">
          <div className="grid grid-cols-1 lg:grid-cols-3 space-y-8 lg:space-y-0">
            <div className="container-logo-footer">
              <img
                className="h-24 lg:h-44"
                src="../../../assets/illustrations/logo-large.png"
                alt=""
              />
            </div>
            <div className="lg:ml-auto hidden lg:inline">
              <h3 className="text-base font-bold">PAGE</h3>
              <div className="flex flex-col space-y-4 mt-6">
                <Link to="/service">
                  <span className="text-sm">Services</span>
                </Link>
                <Link to="/project">
                  <span className="text-sm">Projects</span>
                </Link>
                <Link to="/career">
                  <span className="text-sm">Career</span>
                </Link>
                <Link to="/about-us">
                  <span className="text-sm">About Us</span>
                </Link>
              </div>
            </div>
            <div className="lg:ml-auto hidden lg:inline">
              <h3 className="text-base font-bold">CONTACT</h3>
              <div className="flex flex-col space-y-4 mt-6">
                <a href="" className="text-sm">
                  +62 813-1747-3439
                </a>
                <a href="" className="text-sm">
                  CS@znitech.dev
                </a>
                <a href="" className="text-sm">
                  @znitech
                </a>
                <a href="" className="text-sm">
                  BSD, Banten, Indonesia
                </a>
              </div>
            </div>
          </div>
          <div className="lg:hidden mt-12">
            <div className="grid grid-cols-2">
              <div className="">
                <h3 className="text-base font-bold">PAGE</h3>
                <div className="flex flex-col space-y-4 mt-6">
                  <Link to="/service">
                    <span className="text-sm">Services</span>
                  </Link>
                  <Link to="/project">
                    <span className="text-sm">Projects</span>
                  </Link>
                  <Link to="/career">
                    <span className="text-sm">Career</span>
                  </Link>
                  <Link to="/about-us">
                    <span className="text-sm">About Us</span>
                  </Link>
                </div>
              </div>
              <div className="">
                <h3 className="text-base font-bold">CONTACT</h3>
                <div className="flex flex-col space-y-4 mt-6">
                  <a href="" className="text-sm">
                    +62 813-1747-3439
                  </a>
                  <a href="" className="text-sm">
                    CS@znitech.dev
                  </a>
                  <a href="" className="text-sm">
                    @znitech
                  </a>
                  <a href="" className="text-sm">
                    BSD, Banten, Indonesia
                  </a>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="bg-gray-900">
        <div className="mt-8 mx-auto max-w-7xl px-4 sm:px-6 lg:px-8 py-4 bg-gray-900">
          <p className="text-gray-50 text-xs">
            Copyright 2023. Znitech. All right reserved
          </p>
        </div>
      </div>
    </>
  );
}

export default Footer;
