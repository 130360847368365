import React from "react";
import { Carousel } from "@material-tailwind/react";
import Navbar from "../../components/navbar/navbar";
import Footer from "../../components/footer/footer";

function Career() {
  return (
    <div>
      <Navbar active="career" />
      <div className="container mx-auto max-w-7xl px-4 sm:px-6 lg:px-8">
        <section>
          <div className="grid grid-cols-1 lg:grid-cols-2 justify-center items-center lg:py-14">
            <div className="justify-center items-center mt-8 lg:mt-0 order-2 lg:order-1">
              <h1 className="text-4xl lg:text-7xl font-black text-gray-950">
                Grow Together <span className="text-[#FF84FC]">With Us</span>
              </h1>
              <p className="py-4 lg:w-10/12 text-xl">
                We need new talents, professionals and creative people who
                willing to work in team.
              </p>
            </div>
            <div className="justify-center items-center lg:ml-auto order-1 lg:order-2">
              <img
                className="object-contain"
                src="../../assets/illustrations/logo-2.png"
                alt=""
              />
            </div>
          </div>
        </section>
        <section>
          <div className="grid grid-cols-1 lg:grid-cols-2 justify-center items-center lg:py-14">
            <div className="justify-center items-center">
              <img
                className="w-full h-full lg:h-[480px] lg:w-[480px]"
                src="../assets/illustrations/ilus-4.png"
                alt=""
              />
            </div>
            <div className="mt-12 lg:mt-0">
              <h1 className="text-2xl lg:text-4xl font-black text-gray-950">
                How we work at Znitech?
              </h1>
              <p className="text-base lg:text-lg lg:w-9/12 text-gray-950 mt-6">
                Our company culture is Pals. We want to be the best partner for
                our clients.{" "}
              </p>
              <p className="text-base lg:text-lg lg:w-9/12 text-gray-950 mt-6">
                We are a team player, with strong personal skill. Adaptable to
                work with our client culture and values, and willing to learn
                new thing.{" "}
              </p>
              <p className="text-base lg:text-lg lg:w-9/12 text-gray-950 mt-6">
                We take our job seriously because we want to simplify our
                partners effort to achieving their goals.
              </p>
              <p className="text-base lg:text-lg lg:w-9/12 text-gray-950 mt-6">
                If you have the same passion, come join us at Znitech Team!
              </p>
            </div>
          </div>
          <h1 className="text-2xl lg:text-4xl font-black text-gray-950 text-center mt-14 lg:mt-0">
            Check Our Open Position
          </h1>
          <p className="text-xl m-auto font-normal text-gray-950 text-center mt-14 lg:w-4/12">
            Sorry! We currently do not have any open position. See you next
            time!
          </p>
        </section>
      </div>
      <Footer />
    </div>
  );
}

export default Career;
