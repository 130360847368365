import React from "react";
import { Carousel } from "@material-tailwind/react";
import Navbar from "../../components/navbar/navbar";
import Footer from "../../components/footer/footer";

function AboutUs() {
  return (
    <div>
      <Navbar active="about-us" />
      <div className="container mx-auto max-w-7xl px-4 sm:px-6 lg:px-8">
        <section>
          <div className="grid grid-cols-1 lg:grid-cols-2 justify-center items-center py-14">
            <div className="justify-center items-center mt-8 lg:mt-0 order-2 lg:order-1 text-center lg:text-left">
              <h1 className="text-4xl lg:text-7xl font-black text-gray-950">
                Turning Ideas{" "}
                <span className="text-[#FF84FC]">Into Reality</span>
              </h1>
              <p className="py-4 lg:w-7/12 text-xl">
                Hi! Welcome to Znitech. Let’s get to know us better!
              </p>
            </div>
            <div className="justify-center items-center m-auto order-1 lg:order-2">
              <img
                className="object-contain"
                src="../../assets/illustrations/logo-2.png"
                alt=""
              />
            </div>
          </div>
        </section>
        <section>
          <div className="grid grid-cols-1 lg:grid-cols-2 justify-center items-center lg:py-14">
            <div className="justify-center items-center">
              <img
                className="w-full h-full lg:h-[480px] lg:w-[480px]"
                src="../assets/illustrations/ilus-2.png"
                alt=""
              />
            </div>
            <div className="mt-12 lg:mt-0">
              <h1 className="text-2xl lg:text-4xl font-black text-gray-950">
                About Us
              </h1>
              <p className="text-base lg:text-lg lg:w-9/12 text-gray-950 mt-6">
                Our team have collaborated with many companies in building
                custom mobile and website app.{" "}
              </p>
              <p className="text-base lg:text-lg lg:w-9/12 text-gray-950 mt-6">
                We have a vision to help you (whether you’re corporate or
                start-up) to solve your IT problem.{" "}
              </p>
              <p className="text-base lg:text-lg lg:w-9/12 text-gray-950 mt-6">
                We work through collaboration, we are the extra hand to your
                team to help you achieve your business goal.
              </p>
            </div>
          </div>
        </section>
        <section>
          <div className="grid grid-cols-1 lg:grid-cols-2 justify-center items-center lg:py-14">
            <div className="mt-12 lg:mt-0">
              <h1 className="text-2xl lg:text-4xl font-black text-gray-950">
                Our Mission
              </h1>
              <p className="text-base lg:text-lg lg:w-9/12 text-gray-950 mt-6">
                We know that developing and maintaining your business is tough,
                that why we position ourselves as a full IT Solutions team, a
                reliable partner for you.{" "}
              </p>
              <p className="text-base lg:text-lg lg:w-9/12 text-gray-950 mt-6">
                We will help you to get necessary assistance for your tech
                problem..{" "}
              </p>
              <p className="text-base lg:text-lg lg:w-9/12 text-gray-950 mt-6">
                We are committed to help our partner to grow their product and
                business.
              </p>
            </div>
            <div className="justify-center items-center mt-6 lg:mt-0">
              <img
                className="w-full h-full lg:h-[480px] lg:w-[480px]"
                src="../assets/illustrations/ilus-3.png"
                alt=""
              />
            </div>
          </div>
          <h1 className="text-2xl lg:text-4xl font-black text-gray-950 text-center mt-24">
            Collaborate with Us!
          </h1>
          <div className="flex items-center justify-center mt-14">
            <a
              href="https://wa.me/+6281317473439"
              className="inline-flex text-center items-center border-transparent px-4 mt-1 py-2 self-center rounded-md text-lg font-semibold text-white bg-[#47E0E2]"
            >
              Contact Us
            </a>
          </div>
        </section>
      </div>
      <Footer />
    </div>
  );
}

export default AboutUs;
